@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400; /* 레귤러 */
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
  font-weight: 500; /* 미디엄 */
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600; /* 세미볼드 */
  font-style: normal;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}

.copyright-stories {
  background-color: #ffffff;
  color: #6a6a6a;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #f1f1f3;
  z-index: 5;
  height: 30px;
  position: relative;
  bottom: 5px;
  padding-top: 15px;
}



.main-banner {
  color: #ffffff;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 100%;
  height: 45px;
  margin-top: 70px;
  border-radius: 3px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
}


/* cs페이지 채널톡 커스텀 버튼 */
.custom-button-1 {
  width: 100vw;
  height: calc(100vh - 90px); /* 전체 뷰포트 높이에서 내비게이션 높이 제외 */
}

.cssupporticon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 공통 스켈레톤 스타일 */
.skeleton {
  background-color: #e4e4e4;
  border-radius: 3px;
  animation: skeleton-loading 1.5s infinite;
}

.skeleton-product-thumbnail {
  border-radius: 3px;
  width: 100px;
  height: 150px;
  background-color: #e4e4e4;
}

.skeleton-product-name {
  width: 150px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 19px;
  margin-bottom: 5px;
}

.skeleton-who-wrote-this {
  width: 60px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 12px;
  margin-bottom: 5px;
}

.skeleton-detail-review-rating-star {
  width: 100px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 19.5px;
  margin-bottom: 5px;
}

.skeleton-product-price {
  width: 120px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 27px;
  margin-bottom: 5px;
}

.skeleton-add-to-cart {
  border-radius: 3px;
  background-color: #e4e4e4;
  width: 135px;
  height: 33px;
}

.skeleton-cart-product-thumbnail {
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 114px;
  width: 76px;
}

.skeleton-cart-product-name {
  width: 150px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 19px;
  margin-bottom: 5px;
}

.skeleton-cart-who-wrote-this {
  width: 60px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 12px;
  margin-bottom: 5px;
}

.skeleton-cart-product-price {
  width: 90px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 21.5px;
}

.skeleton-purchasehistory-product-thumbnail {
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 114px;
  width: 76px;
}

.skeleton-purchase-date {
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 17px;
  width: 150px;
  margin-bottom: 5px;
}

.skeleton-purchasehistory-who-wrote-this {
  width: 60px;
  border-radius: 3px;
  background-color: #e4e4e4;
  height: 17px;
  margin-bottom: 5px;
}

.skeleton-purchasehistory-product-price {
  height: 19.5px;
  width: 90px;
  background-color: #e4e4e4;
  border-radius: 3px;
}

.skeleton-purchasehistory-review-btn {
  border-radius: 3px;
  background-color: #e4e4e4;
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
}

.skeleton-purchasehistory-cancleorder-btn {
  border-radius: 3px;
  background-color: #e4e4e4;
  width: 100%;
  height: 50px;
}

.skeleton-reading-content {
  width: 100%;
  height: 80%;
  border-radius: 3px;
  background-color: #e4e4e4;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25px;
}

.skeleton-readinglist-item {
  display: flex;
  width: 100%;
  height: 100%;
}

.skeleton-readinglist-thumbnail {
  border-radius: 3px;
  width: 100%;
  aspect-ratio: 100 / 150; /* 비율 유지 */
  background-color: #e4e4e4;
  border-radius: 3px;
}

.skeleton-profileicon-profileeditpage {
  width: 125px;
  height: 125px;
  border-radius: 100px;
  background-color: #e4e4e4;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}


body {
  margin: 0px;
  color: #222222;
  font-family: "Pretendard", sans-serif;
  font-weight: 500; /* 기본값을 medium으로 설정 */
}

input,
textarea,
select,
input::placeholder,
textarea::placeholder {
  font-family: "Pretendard", sans-serif;
  font-weight: 500;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

button {
  padding: 0;
  font-family: "Pretendard", sans-serif;
  font-weight: 500; /* 기본값을 medium으로 설정 */
  -webkit-appearance: none; /* iOS 기본 버튼 스타일 제거 */
  -moz-appearance: none; /* Firefox 기본 버튼 스타일 제거 */
  appearance: none; /* 기타 브라우저 기본 버튼 스타일 제거 */
  text-decoration: none; /* 링크 스타일 제거 */
}

.linktag-cart {
  width: 20px;
  height: 21px;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 9px;
  padding-right: 9px;
}

.linktag-profile {
  width: 22px;
  height: 22px;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 9px;
  padding-right: 9px;
}

.linktag-search {
  width: 19px;
  height: 19px;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 9px;
  padding-right: 9px;
}

.linktag-menu {
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 9px;
}

.phone-number-container {
  position: relative; /* 부모 요소에 상대적 위치 설정 */
}

.sms-send {
  position: absolute; /* 절대 위치 설정 */
  top: 24px;
  right: 10px; /* 입력 필드 오른쪽에서 10px 떨어진 위치 */
  transform: translateY(-50%); /* 이미지 수직 중앙 정렬 */
  width: 30px;
  height: 30px;
  margin-top: 10px;
}

.forgotpassword2-sms-send {
  position: absolute; /* 절대 위치 설정 */
  top: 24px;
  right: 10px; /* 입력 필드 오른쪽에서 10px 떨어진 위치 */
  transform: translateY(-50%); /* 이미지 수직 중앙 정렬 */
  width: 30px;
  height: 30px;
  margin-top: 10px;
}

.HomeGrid {
  display: Grid;
  grid-template-areas:
    "a"
    "b";
  grid-template-rows: 50px auto;
  width: 100%;
  height: 100%;
}

.Navbar {
  display: grid;
  grid-area: a;
  grid-template-areas: "a1 a2";
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 50px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index : 3;
}

.a1 {
  display: flex;
  align-items: center;
  grid-area: a1;
  margin-left: 4vw;
}

.a2 {
  display: flex;
  justify-content: flex-end;
  grid-area: a2;
  margin-right: 4vw;
}

.b1 {
  display: flex;
  align-items: center;
  grid-area: b1;
  position: relative;
}

.product-thumbnail {
  border-radius: 3px;
  width: 100px;
  height: 150px;
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 0.5);
}


.write-product-thumbnail {
  border-radius: 3px;
  width: 100px;
  height: 150px;
}

.product-thumbnail-img {
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 1);
  border-radius: 3px;
  width: 100px;
  height: 150px;
}

.b2 {
  display: flex;
  align-items: center;
  grid-area: b2;
}

.a2-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  height: 100%;
}

.ProductList {
  margin-top: 60px;
  grid-area: b;
  width: 100%;
  height: 100%;
}

.home-product {
  display: grid;
  grid-template-areas: "b1 b2";
  width: 100%;
  height: 180px;
  grid-template-columns: 120px 2fr;
  margin-top: 12px;
  border-bottom: solid 1.5px #f1f1f3;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  padding-bottom: 12px;
}

.logo {
  width: 91px;
  height: 22px;
}

.carticon {
  width: 20px;
  height: 21px;
}

.profileicon {
  width: 22px;
  height: 22px;
}

.searchicon {
  width: 19px;
  height: 19px;
}

.menuicon {
  width: 18px;
  height: 14px;
}

.product-container {
  width: 100%;
  height: 150px;
}

.product-name {
  display: -webkit-box;
  width: 100%;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 19px; /* 한 줄일 때의 높이 */
  max-height: 38px; /* 두 줄일 때의 최대 높이 */
  -webkit-line-clamp: 2; /* 최대 2줄까지 표시 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.who-wrote-this {
  font-size: 12px;
  color: #6a6a6a;
  display: flex;
  width: 100%;
  height: 12px;
  margin-bottom: 7px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #222222;
  border-radius: 50%; /* 원 형태로 만들기 */
  margin: 0 5px; /* 원 사이의 간격 조절 */
  margin-right: 8px;
  align-self: center; /* 세로 가운데 정렬 */
}

.review-rating {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 3px;
}

.rating {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 1px;
}

.product-price {
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: #222222;
}

.add-to-cart {
  width: 135px;
  height: 33px;
  border-radius: 5px;
  background-color: #222222;
  border: #222222;
  color: #ffffff;
  font-size: 16px;
}

.search-container {
  display: grid;
  width: 100%;
  height: 40px;
  align-items: center;
  margin-top: 15px;
  grid-template-areas: "a1 a2";
  grid-template-columns: 50px auto;
}

.search-box-container {
  display: flex;
  grid-area: a2;
  margin-right: 4vw;
  position: relative;
  align-items: center;
}

.search-box {
  background-color: #f1f1f3;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none; /* 기존에 있는 모든 테두리 스타일 제거 */
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
}

.search-box::placeholder {
  color: #6a6a6a; /* 원하는 색상으로 변경 */
  opacity: 50%;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
}

.search-box:not(:placeholder-shown) {
  color: #222222; /* 텍스트가 입력된 경우 텍스트 색상 변경 */
}

.backicon {
  width: 20px;
  height: 20px;
}

.backicon-container {
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 10px;
  padding-bottom: 10px;
  grid-area: a1;
  display: grid;
  display: flex;
  justify-content: center;
}

.delete-icon {
  position: absolute;
  right: 15px; /* 입력 상자 오른쪽 끝에서의 거리 */
  width: 20px; /* 아이콘 크기 */
  height: 20px; /* 아이콘 크기 */
  align-items: center;
  padding: 10px 0 10px 10px;
}

.signin-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* 수평 스크롤 방지 */
}

.close-container {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-left: 4vw;
}

.closeicon {
  width: 20px;
  height: 20px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.write-closeicon {
  width: 15px;
  height: 15px;
  position: fixed;
  bottom: 283px;
  left: 24px;
  z-index: 2;
}

.closeicon2 {
  width: 15px;
  height: 15px;
}

.menucloseicon {
  width: 16px;
  height: 16px;
  padding: 12px 0px 12px 12px;
}

.write-close-modal {
  width: 100%;
  height: 330px;
  box-shadow: 0px 0px 4px 0px rgba(34, 34, 34, 0.5);
  background-color: #ffffff;
  position: fixed; /* 브라우저 화면 기준으로 위치 설정 */
  z-index: 1;
  bottom: 0px;
  left: 0px;
  border-radius: 25px 25px 0px 0px;
}

.write-close-modal-background {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6); /* 완전 검정에 0.5 투명도 */
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.write-modal-yes-btn {
  width: 91%;
  height: 50px;
  background-color: #222222;
  border-radius: 5px;
  position: fixed; /* 브라우저 화면 기준으로 위치 설정 */
  bottom: 100px;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.write-modal-no-btn {
  width: 90%;
  height: 50px;
  border: #222222 solid 2px;
  border-radius: 5px;
  position: fixed; /* 브라우저 화면 기준으로 위치 설정 */
  bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.write-close-modal-title {
  position: fixed;
  font-size: 24px;
  bottom: 215px;
  left: 21px;
  width: 100%;
  height: 50px;
}

.write-close-modal-body {
  width: 100%;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.logo-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 47px;
  margin-bottom: 30px;
}

.signin-logo {
  width: 170px;
}

.idpw-form {
  width: 100%;
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.signin-id,
.signin-pw {
  width: 100%;
  height: 45px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  padding: 0px;
  border: solid 1.5px rgba(106, 106, 106, 0.5); /* 테두리 투명도 50% */
  border-radius: 5px;
  font-size: 16px; /* 텍스트 크기 16px로 변경 */
  margin-top: 10px;
}

.signin-id::placeholder,
.signin-pw::placeholder {
  color: #6a6a6a; /* 원하는 색상으로 변경 */
  opacity: 50%;
}

.signin-id:not(:placeholder-shown),
.signin-pw:not(:placeholder-shown) {
  color: rgba(
    34,
    34,
    34,
    1
  ); /* 텍스트가 입력된 경우 텍스트 색상 #222222 투명도 100% */
  border-color: rgba(34, 34, 34, 1); /* 테두리 색상 #222222 투명도 100% */
}

.signin-submit-btn {
  width: 100%;
  height: 48px;
  margin-top: 3%;
  background-color: #222222;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
}

.idpw-find-container {
  width: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 3%;
  font-size: 14px;
}

.signup {
  background-color: #ffffff;
  padding-bottom: 4.5%;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.pw-find {
  padding: 0 4.5% 4.5% 4.5%; /* 위쪽, 오른쪽, 아래쪽, 왼쪽 순서 */
  background-color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.easy-signin {
  width: 100%;
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 7%;
}

.naver-signin,
.kakao-signin,
.facebook-signin,
.google-signin {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1.5px solid rgba(106, 106, 106, 0.5); /* 테두리 투명도 50% */
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #6a6a6a;
}

.naver,
.kakao,
.facebook,
.google {
  width: 26px;
  margin-left: 15px;
  margin-right: 10px;
}

.signup-container {
  width: 100%;
  height: 100%;
}

/* Nav position */

.signup-nav-container,
.forgotpassword-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
  font-weight: 600;
}

.backicon {
  position: absolute;
  left: 0;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.signup-box {
  width: 100%;
  margin-top: 40px;
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.signup-text,
.forgot-text {
  font-size: 24px;
  font-weight: 600;
}

.verify-code {
  width: 114px;
  height: 45px;
  border: solid 1.5px rgba(106, 106, 106, 0.5); /* 테두리 투명도 50% */
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
  letter-spacing: 5px; /* 원하는 간격(px)으로 설정 */
}

.verify-code-failure {
  width: 114px;
  height: 45px;
  border: solid 1.5px #de1c24;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
  letter-spacing: 5px; /* 원하는 간격(px)으로 설정 */
}

.newpassword-error,
.newpasswordconfirm-error {
  border: solid 1.5px #de1c24;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
  margin-top: 10px;
}

.signup-id,
.signup-password,
.reconfirm-password,
.phone-number,
.nickname,
.newpassword,
.newpasswordconfirm {
  width: 100%;
  height: 45px;
  border: solid 1.5px rgba(106, 106, 106, 0.5); /* 테두리 투명도 50% */
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
}

.verify-code::placeholder {
  letter-spacing: 0; /* placeholder의 글자 간격을 0으로 설정 */
  color: #6a6a6a; /* 원하는 색상으로 변경 */
  opacity: 50%;
}

/* placeholder 텍스트의 투명도를 50%로 설정 */
.signup-id::placeholder,
.signup-password::placeholder,
.reconfirm-password::placeholder,
.phone-number::placeholder,
.nickname::placeholder,
.newpassword::placeholder,
.newpasswordconfirm::placeholder {
  color: #6a6a6a; /* 원하는 색상으로 변경 */
  opacity: 50%;
}

.signup-id:not(:placeholder-shown),
.signup-password:not(:placeholder-shown),
.reconfirm-password:not(:placeholder-shown),
.phone-number:not(:placeholder-shown),
.nickname:not(:placeholder-shown),
.verify-code:not(:placeholder-shown),
.newpassword:not(:placeholder-shown),
.newpasswordconfirm:not(:placeholder-shown) {
  color: rgba(
    34,
    34,
    34,
    1
  ); /* 텍스트가 입력된 경우 텍스트 색상 #222222 투명도 100% */
  border-color: rgba(34, 34, 34, 1); /* 테두리 색상 #222222 투명도 100% */
}

.complete-btn,
.next-btn,
.bottom-btn {
  width: 100%;
  height: 48px;
  padding: 0px;
  margin-left: 4vw;
  margin-bottom: 10px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  background-color: #222222;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  position: fixed;
  bottom: 25px;
}

.see-more-reviews-btn {
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  background-color: #222222;
  color: #ffffff;
  margin-bottom: 25px;
  border: none;
  border-radius: 5px;
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.see-more-reviews-btn img {
  margin-left: 10px;
}

.forgot-box {
  width: 100%;
  margin-top: 40px;
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.forgot-box-error-id {
  width: 100%;
  height: 45px;
  border: 1.5px solid #de1c24;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
}

.forgot-box-id {
  width: 100%;
  height: 45px;
  border: 1.5px solid rgba(106, 106, 106, 0.5); /* 테두리 투명도 50% */
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
}

.forgot-box-id::placeholder {
  color: #6a6a6a; /* 원하는 색상으로 변경 */
  opacity: 50%;
}

.forgot-box-id:not(:placeholder-shown) {
  color: rgba(
    34,
    34,
    34,
    1
  ); /* 텍스트가 입력된 경우 텍스트 색상 #222222 투명도 100% */
  border-color: rgba(34, 34, 34, 1); /* 테두리 색상 #222222 투명도 100% */
}

.changepassword-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.complete-box {
  width: 100%;
  margin-top: calc(18% + 54px);
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.change-box-pw1,
.change-box-pw2 {
  width: 100%;
  height: 45px;
  border: 1.5px solid rgba(106, 106, 106, 0.5); /* 테두리 투명도 50% */
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
}

.change-box-pw1::placeholder,
.change-box-pw2::placeholder {
  color: #6a6a6a; /* 원하는 색상으로 변경 */
  opacity: 50%;
}

.change-box-pw1:not(:placeholder-shown),
.change-box-pw2:not(:placeholder-shown) {
  color: rgba(
    34,
    34,
    34,
    1
  ); /* 텍스트가 입력된 경우 텍스트 색상 #222222 투명도 100% */
  border-color: rgba(34, 34, 34, 1); /* 테두리 색상 #222222 투명도 100% */
}

.CartGrid {
  width: 100vw;
  height: 100%;
}

.cart-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
  font-weight: 600;
}

.cart-product-container {
  height: 100%;
  width: 100%;
  padding-bottom: 150px; /* 체크아웃 컨테이너의 높이만큼 패딩 추가 */
}

.cart-checkout-product-container {
  height: 100%;
  width: 100%;
}

.cart-checkout-border-bottom {
  border-bottom: solid 1.3px #f1f1f3;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 30px;
  margin-bottom: 33px;
}

.payment-select {
  display: flex;
  align-items: center;
  height: 25px;
  justify-content: space-between;
  padding-bottom: 13px;
}

.payment-select input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #f1f1f3; /* 선택되지 않은 상태에서의 테두리 색상 */
  appearance: none; /* 기본 radio 버튼 스타일 제거 */
  cursor: pointer;
  background-color: #ffffff; /* 기본 배경 색상 */
  position: relative; /* 내부 원을 위한 위치 설정 */
  margin: 0;
}

.payment-select input[type="radio"]:checked {
  border: 2px solid #222222; /* 선택된 상태에서의 테두리 색상 */
  background-color: #ffffff; /* 선택된 상태에서의 배경 색상 */
}

.payment-select input[type="radio"]:checked::before {
  content: ""; /* 내부 원을 위한 내용 설정 */
  width: 12px; /* 내부 원의 너비 */
  height: 12px; /* 내부 원의 높이 */
  border-radius: 50%; /* 내부 원을 원형으로 만들기 */
  background-color: #222222; /* 내부 원의 색상 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 내부 원을 중앙에 배치 */
}

.payment-method-text {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.cart-checkout-product-text {
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25px;
  margin-bottom: 13px;
  font-size: 18px;
  font-weight: 600;
}

.cart-product {
  width: 100%;
  height: 120px;
  display: grid;
  grid-template-areas: "a1 a2 a3";
  grid-template-columns: 90px auto 25px;
  margin-top: 25px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.cart-checkout-product {
  width: 100%;
  height: 120px;
  display: grid;
  grid-template-areas: "a1 a2 a3";
  grid-template-columns: 90px auto 25px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-bottom: 25px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.payment-method-container {
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  padding-bottom: 140px; /* 체크아웃 컨테이너의 높이만큼 패딩 추가 */
}

.cart-a1 {
  grid-area: a1;
  width: 100%;
}

.cart-a2 {
  grid-area: a2;
  width: 100%;
}

.cart-product-thumbnail {
  width: 76px;
  height: 114px;
  border-radius: 3px;
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 0.5);
}

.cart-product-name {
  display: -webkit-box;
  width: 100%;
  font-size: 16px;
  margin-bottom: 3px;
  line-height: 19px; /* 한 줄일 때의 높이 */
  max-height: 38px; /* 두 줄일 때의 최대 높이 */
  -webkit-line-clamp: 2; /* 최대 2줄까지 표시 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.cart-who-wrote-this {
  font-size: 12px;
  color: #6a6a6a;
  margin-bottom: 3px;
}

.cart-product-price {
  font-size: 18px;
  font-weight: 600;
}

.cart-a3 {
  grid-area: a3;
  display: flex;
  justify-content: center;
}

.cart-closeicon {
  width: 12px;
  height: 12px;
  margin-top: 1.5px;
  margin-left: 5px;
}

.checkout-container {
  width: 100%;
  height: 130px;
  box-shadow: 0px -1px 3px 0.3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 3;
}

.total-container {
  display: flex;
  margin-left: 4vw;
  margin-right: 4vw;
  width: 100%;
  height: 15px;
  justify-content: space-between;
  margin-top: 15px;
}

.continue-checkout {
  background: #222222;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  height: 48px;
  color: white;
  font-size: 18px;
  margin-bottom: 20px;
}

.total,
.total-price {
  font-size: 18px;
}

.detail-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.detail-grid-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "a"
    "b"
    "c"
    "d";
}

.detail-a {
  width: 100%;
  height: 100%;
  grid-area: a;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  display: flex;
  flex-direction: column;
  border-bottom: 1.3px solid #f1f1f3;
}

.detail-product-name {
  display: -webkit-box;
  width: 100%;
  line-height: 28px; /* 한 줄일 때의 높이 */
  max-height: 55px; /* 두 줄일 때의 최대 높이 */
  margin-top: 27px;
  margin-bottom: 8px;
  -webkit-line-clamp: 2; /* 최대 2줄까지 표시 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #222222;
  font-size: 24px;
  font-weight: 600;
}

.detail-product-price {
  font-size: 18px;
  color: #222222;
  margin-bottom: 35px;
}

.detail-thumbnail {
  width: 192px;
  height: 287px;
  border-radius: 3px;
  margin-bottom: 25px; /* 추가: 이미지와 버튼 사이의 간격 */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 0.5);
}

.detail-buynow {
  width: 192px;
  height: 52px;
  background-color: #222222;
  color: #ffffff;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  margin-bottom: 8px; /* 추가: 버튼들 사이의 간격 */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}

.detail-addtocart {
  width: 192px;
  height: 52px;
  color: #222222;
  background-color: #ffffff;
  font-size: 18px;
  border-radius: 5px;
  border: solid 2px #222222;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.detail-b {
  height: 100%;
  width: 100%;
  grid-area: b;
  margin-left: 4vw;
  margin-right: 4vw;
  border-bottom: 1.3px solid #f1f1f3;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.book-introduce {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
}

.book-introduce-detail {
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 400;
}

.detail-c {
  height: 100%;
  width: 100%;
  grid-area: c;
  margin-left: 4vw;
  margin-right: 4vw;
  border-bottom: 1.3px solid #f1f1f3;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.detail-imformation {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
}

.detail-c1,
.detail-c2,
.detail-c3 {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: 400;
}

.detail-c1 span,
.detail-c2 span,
.detail-c3 span {
  margin-top: 2px;
}

.detail-c3 {
  margin-bottom: 40px;
}

.detail-c1 img,
.detail-c2 img,
.detail-c3 img {
  margin-right: 13px;
}

.detail-c1 span,
.detail-c2 span,
.detail-c3 span {
  font-size: 18px;
}

.detail-d {
  height: 100%;
  width: 100%;
  grid-area: d;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.detail-d1 {
  display: flex;
  margin-top: 40px;
  margin-bottom: 25px;
  justify-content: space-between;
}

.detail-d1-1 {
  display: flex;
  align-items: center;
}

.detail-d1-2 {
  display: flex;
  align-items: center;
}

.detail-d2-container {
  margin-bottom: 40px;
}

.detail-d2 {
  margin-bottom: 30px;
}

.detail-onestar {
  margin-right: 5px;
  margin-bottom: 1px;
  width: 20px;
  height: 20px;
}

.detail-review-score {
  font-size: 24px;
  margin-right: 5px;
  font-weight: 600;
}

.detail-number-of-reviews {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2px;
}

.detail-d2-1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-d2-1 div {
  margin-bottom: 3px;
}

.detail-profile-img {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.detail-review-dot {
  width: 2px;
  height: 2px;
  background-color: #222222;
  border-radius: 50%; /* 원 형태로 만들기 */
  margin: 0 5px; /* 원 사이의 간격 조절 */
  margin-right: 6px;
}

.detail-review-rating-star {
  display: flex;
}

.detail-review-rating-star img {
  width: 14px;
  height: 14px;
  margin-right: 1.5px;
}

.detail-d2-2 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.detail-d2-3 {
  font-weight: 400;
}

.detail-review-date {
  font-size: 14px;
}

.review-write-icon {
  margin-bottom: 2px;
  margin-right: 5px;
}

.review-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.review-container1 {
  margin-top: 27px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  display: flex;
  border-bottom: 1.3px solid #f1f1f3;
  justify-content: space-between;
}

.review-container1-1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review-container1-2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.reviewpage-write-icon {
  margin-right: 5px;
  margin-bottom: 4px;
}

.reviewpage-write-icon-text {
  margin-bottom: 3px;
}

.review-score {
  font-size: 30px;
  margin-top: 5px;
  font-weight: 600;
}

.review-bigonestar {
  margin-right: 8px;
  width: 22px;
  height: 22px;
  margin-top: 5px;
}

.review-container2 {
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 30px;
}

.review-container2-1 {
  margin-top: 15px;
}

.review-container2-2 {
  margin-top: 10px;
  padding: 10px 0px 10px 10px;
  display: flex;
  align-items: center;
}

.review-container3-3 {
  font-weight: 400;
}

.review-filter {
  margin-top: 3px;
  font-weight: 600;
}

.review-dropdown {
  margin-left: 5px;
  margin-top: 2px;
}

.review-number-of-reviews {
  font-size: 24px;
  font-weight: 600;
}

.review-container3 {
  margin-bottom: 30px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.review-container3-1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review-profile-img {
  margin-right: 10px;
}

.review-container3-2 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.review-review-date {
  font-size: 14px;
}

.readinglist-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
  font-weight: 600;
}

.readinglist-container {
  width: 100%;
  height: 100%;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 아이템을 한 줄에 */
  gap: 5px; /* 아이템 사이의 간격 */
}

.readinglist-item {
  display: flex;
  width: 100%;
  height: 100%;
}

.readinglist-thumbnail {
  border-radius: 3px;
  width: 100%;
  aspect-ratio: 100 / 150; /* 비율 유지 */
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 0.5);
}

.profile-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.profile-container1 {
  margin-top: 25px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  height: 50px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1.3px solid #f1f1f3;
}

.profile-container1-1 {
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-right: 13px;
  width: 45px;
}

.profile-container1-2 {
  width: 150px;
}

.profile-container1-3 {
  display: flex;
  align-items: center;
  margin-left: auto; /* 추가 */
}

.profile-name {
  margin-bottom: 1px;
}

.profile-noprofileicon {
  width: 45px;
  height: 45px;
}

.profileicon-profilepage {
  width: 45px;
  height: 45px;
  border-radius: 100px;
}

.profileeditpage-complete-btn {
  border: none;
  font-size: 16px;
  color: #222222;
  background-color: transparent; /* 배경색을 투명하게 설정 */
  font-weight: 600;
  padding: 10px 0px 10px 10px;
}

.profileicon-profileeditpage {
  width: 125px;
  height: 125px;
  border-radius: 100px;
}

.profile-purchasehistory,
.profile-read,
.profile-cs {
  margin-top: 1px;
}

.edit-text {
  font-size: 12px;
  color: #6a6a6a;
}

.profile-container2 {
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  height: 300px;
  padding-top: 10px;
}

.profile-container2-1,
.profile-container2-2,
.profile-container2-3,
.profile-container2-4 {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.profile-purchasehistory,
.profile-editpost,
.profile-read,
.profile-cs {
  margin-left: 13px;
  margin-right: 15px;
}

.profile-next-icon-container {
  display: flex;
  margin-left: auto; /* 추가 */
}

.profile-next-icon-item {
  margin-right: 0px;
}

.profileedit-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.profileedit-noprofileicon-container {
  width: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 70px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  display: flex;
  justify-content: center;
}

.profileedit-noprofileicon {
  width: 125px;
  height: 125px;
}

.profileedit-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: -17px;
}

.photo-img-edit-text {
  width: 70px;
}

.profileedit-name {
  display: flex;
  justify-content: center;
  position: relative;
  top: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  font-size: 16px;
  text-align: center;
}

.revenue-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.revenue-container1 {
  width: 100%;
  margin-top: 150px;
  margin-left: 4vw;
  margin-right: 4vw;
  display: flex;
  flex-direction: column; /* 세로로 배치되도록 설정 */
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.revenue-container1-1 {
  font-size: 30px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.revenue-container2 {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 26px;
}

.revenue-container2-1 {
  margin-right: 5px;
  position: relative;
  right: 10px;
}

.revenue-container2-2 {
  position: relative;
  right: 9px;
  bottom: 1px;
}

.revenue-container2-3 {
  width: 120px;
  font-size: 14px;
  padding: 0px;
  position: relative;
  bottom: 1px;
  left: 12px;
  border: none;
}

.purchasehistory-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
  font-weight: 600;
}

.purchasehistory-product-container {
  width: 100%;
  height: 100%;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25px;
}

.purchasehistory-product {
  width: 100%;
  height: 260px;
  display: grid;
  grid-template-areas:
    "a1 a2 a3"
    "a4 a4 a4";
  grid-template-columns: 90px auto 90px;
  margin-bottom: 50px;
}

.purchasehistory-a1 {
  grid-area: a1;
  width: 100%;
}

.purchasehistory-product-thumbnail {
  width: 76px;
  height: 114px;
  border-radius: 3px;
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 0.5);
}

.purchasehistory-a2 {
  grid-area: a2;
  width: 100%;
}

.purchase-date {
  width: 100%;
  font-size: 14px;
  color: #6a6a6a;
  margin-bottom: 3px;
  font-weight: 400;
}

.purchasehistory-who-wrote-this {
  font-size: 14px;
  color: #222222;
  margin-bottom: 3px;
}

.purchasehistory-product-price {
  font-size: 16px;
  font-weight: 600;
}

.purchasehistory-purchase-text {
  margin-left: 5px;
}

.now-read {
  font-size: 14px;
  color: #6a6a6a;
  margin-right: 5px;
}

.nexticon2 {
  width: 7.65px;
  margin-bottom: 1px;
}

.purchasehistory-a4 {
  grid-area: a4;
  height: 110px;
  width: 100%;
}

.purchasehistory-review-btn {
  border: none;
  width: 100%;
  height: 50px;
  background-color: #222222;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 8px;
}

.purchasehistory-cancleorder-btn {
  border: none;
  width: 100%;
  height: 50px;
  background-color: white;
  color: #222222;
  font-size: 18px;
  border-radius: 5px;
  border: solid 2px #222222;
}

.menu-close-container {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.menu-container {
  width: 100%;
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  font-size: 24px;
  margin-top: 25px;
}

.menu-container div {
  padding-bottom: 20px;
  font-weight: 600;
}

.write-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-in-container {
  display: flex;
  align-items: center;
}

.nav-in-container img {
  margin-right: 5px;
}

.nav-text {
  margin-top: 2px;
  border: 0px;
  background-color: white;
  font-size: 16px;
}

.write-grid-container {
  height: 100%;
  width: 100%;
  display: grid;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  grid-template-areas:
    "a"
    "b"
    "c"
    "d";
}

.write-a {
  margin-top: 27px;
  grid-area: a;
  margin-bottom: 40px;
}

.write-a-text {
  font-size: 18px;
  margin-bottom: 7px;
}

.write-a1 {
  display: flex;
  justify-content: space-between;
}

.write-a1-1 {
  display: flex;
  font-size: 16px;
  color: #222222;
}

.write-dropdown {
  width: 13px;
  margin-bottom: 11px;
  margin-left: 6px;
}

.write-b-text {
  font-size: 18px;
  margin-bottom: 7px;
}

.write-b1 {
  border: 1.5px solid #f1f1f3;
  border-radius: 5px;
  width: 100%;
  height: 600px;
}

.write-b1-entered {
  border: 1.5px solid #222222;
  border-radius: 5px;
  width: 100%;
  height: 600px;
}

.write-b1-entered input {
  border: none;
  border-bottom: 1.5px solid #f1f1f3;
  margin-left: 13px;
  margin-right: 13px;
  width: calc(100% - 26px); /* 좌우 마진을 고려하여 너비 계산 */
}

.write-b1 input {
  border: none;
  border-bottom: 1.5px solid #f1f1f3;
  margin-left: 13px;
  margin-right: 13px;
  width: calc(100% - 26px); /* 좌우 마진을 고려하여 너비 계산 */
}

.write-b {
  grid-area: b;
  margin-bottom: 40px;
}

.write-b-product-name {
  margin-bottom: 5px;
  width: 100%;
  padding: 0px;
  height: 40px;
  font-size: 16px;
  color: #222222;
}

.write-b-product-name-entered {
  margin-bottom: 5px;
  width: 100%;
  padding: 0px;
  height: 40px;
  font-size: 16px;
  color: #222222;
  border-bottom: 1.5px solid #222222 !important;
}

.write-b-person-name {
  width: 100%;
  padding: 0px;
  height: 40px;
  font-size: 16px;
  color: #222222;
}

.write-b-person-name-entered {
  margin-bottom: 5px;
  width: 100%;
  padding: 0px;
  height: 40px;
  font-size: 16px;
  color: #222222;
  border-bottom: 1.5px solid #222222 !important;
}

.write-b-product-introduce {
  width: 100%;
  padding: 0px;
  height: 64px;
  font-size: 16px;
  border: none;
  border-bottom: 1.5px solid #f1f1f3;
  color: #222222;
  margin-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  width: calc(100% - 26px); /* 좌우 마진을 고려하여 너비 계산 */
  resize: none; /* 크기 조절 비활성화 */
  padding-bottom: 5px;
  box-sizing: border-box; /* 패딩을 포함하여 크기를 계산 */
}

.write-b-product-introduce-entered {
  width: 100%;
  padding: 0px;
  height: 64px;
  font-size: 16px;
  border: none;
  border-bottom: 1.5px solid #222222 !important;
  color: #222222;
  margin-top: 3px;
  margin-left: 13px;
  margin-right: 13px;
  width: calc(100% - 26px); /* 좌우 마진을 고려하여 너비 계산 */
  resize: none; /* 크기 조절 비활성화 */
  padding-bottom: 5px;
  box-sizing: border-box; /* 패딩을 포함하여 크기를 계산 */
}

.write-b-your-story {
  width: 100%;
  padding: 0px;
  height: 280px;
  font-size: 16px;
  border: none;
  color: #222222;
  margin-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  width: calc(100% - 26px); /* 좌우 마진을 고려하여 너비 계산 */
  resize: none; /* 크기 조절 비활성화 */
}

.write-b-product-name::placeholder,
.write-b-product-introduce::placeholder,
.write-b-your-story::placeholder,
.write-b-person-name::placeholder {
  color: rgba(106, 106, 106, 0.5); /* 텍스트 투명도 50% */
}

.write-c {
  grid-area: c;
  margin-bottom: 40px;
}

.write-c-text {
  font-size: 18px;
}

.write-c1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.write-c2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.write-c2-1 {
  width: 100%;
}

.write-c-timeline-year {
  width: 20%;
  padding: px;
  padding: 0px;
  text-align: center;
  height: 37px;
  border-top-left-radius: 5px; /* 상단 왼쪽 모서리 둥글게 */
  border-bottom-left-radius: 5px; /* 하단 왼쪽 모서리 둥글게 */
  border: solid 1.5px #f1f1f1;
  font-size: 16px;
}

.write-c-timeline-explanation {
  width: 73%;
  padding: 0px;
  padding-left: 10px;
  height: 37px;
  border-left: none;
  border-top: solid 1.5px #f1f1f1;
  border-right: solid 1.5px #f1f1f1;
  border-bottom: solid 1.5px #f1f1f1;
  border-left: none; /* 왼쪽 테두리 제거 */
  border-top-right-radius: 5px; /* 상단 왼쪽 모서리 둥글게 */
  border-bottom-right-radius: 5px; /* 하단 왼쪽 모서리 둥글게 */
  font-size: 16px;
}

.write-c-timeline-year::placeholder,
.write-c-timeline-explanation::placeholder {
  color: rgba(106, 106, 106, 0.5); /* 텍스트 투명도 50% */
}

.write-c-timeline-year::placeholder {
  text-align: center;
}

.write-c-timeline-explanation::placeholder {
  padding-left: 10px;
}

.write-d {
  grid-area: d;
  margin-bottom: 20px;
  position: relative;
}

.write-d-text {
  font-size: 18px;
  margin-bottom: 7px;
}

.write-d-input {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1.5px #f1f1f1;
  width: 100%;
  font-size: 16px;
  text-indent: 18px;
  color: #222222;
}

.write-d-input-entered {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1.5px #222222;
  width: 100%;
  font-size: 16px;
  padding-bottom: 5px;
  text-indent: 18px;
  color: #222222;
}

.write-price-input-false {
  position: relative;
  bottom: -49.5px;
  color: rgba(106, 106, 106, 0.5); /* 텍스트 투명도 50% */
}

.write-price-input-true {
  position: relative;
  bottom: -49.5px;
  color: #222222;
}

.id-error-message {
  font-size: 12px;
  color: #de1c24;
}

.pw-error-message {
  font-size: 12px;
  color: #de1c24;
}

.signin-id-error {
  width: 100%;
  height: 45px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  padding: 0px;
  border: solid 1.5px #de1c24;
  border-radius: 5px;
  font-size: 16px; /* 텍스트 크기 16px로 변경 */
  color: rgba(34, 34, 34, 1);
  margin-top: 10px;
}

.signin-pw-error {
  width: 100%;
  height: 45px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  padding: 0px;
  border: solid 1.5px #de1c24;
  border-radius: 5px;
  font-size: 16px; /* 텍스트 크기 16px로 변경 */
  color: rgba(34, 34, 34, 1);
  margin-top: 10px;
}

.signup-id-error,
.signup-password-error,
.reconfirm-password-error,
.phone-number-error,
.nickname-error {
  border: solid 1.5px #de1c24;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  padding: 0px;
  text-indent: 15px; /* 원하는 위치에 따라 조절 */
  font-size: 16px;
  margin-top: 10px;
}

.signup-id-error-message,
.signup-password-error-message,
.signup-phonenumber-error-message,
.signup-nickname-error-message {
  font-size: 12px;
  color: #de1c24;
  margin-left: 0px;
}

.signup-send-success-message {
  font-size: 12px;
  color: #222222;
  margin-left: 0px;
}

.signup-send-error-message {
  font-size: 12px;
  color: #de1c24;
  margin-left: 0px;
}

.sms-confirmed-success {
  font-size: 12px;
  color: #222222;
  margin-left: 0px;
  margin-top: 4px;
}

.sms-confirmed-pending {
  font-size: 12px;
  color: #222222;
  margin-left: 0px;
  margin-top: 4px;
}

.sms-confirmed-failure {
  font-size: 12px;
  color: #de1c24;
  margin-left: 0px;
  margin-top: 4px;
}

.forgotpassword-error-message {
  font-size: 12px;
  color: #de1c24;
  margin-left: 0px;
}

.forgotpassword3-error-message {
  font-size: 12px;
  color: #de1c24;
  margin-left: 0px;
}

.write-deleteicon3 {
  position: absolute;
  left: 88px;
  top: 115px;
}

.write-category-modal {
  width: 90px;
  height: 130px;
  box-shadow: 0px 0px 4px 0px rgba(34, 34, 34, 0.15);
  border-radius: 5px;
  position: absolute;
  background-color: #ffffff;
  right: 4vw;
  top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.write-option-modal {
  position: absolute;
  top: 54px;
  right: 4vw;
  width: 90px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px rgba(34, 34, 34, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.write-option-modal div {
  font-size: 16px;
  color: #6a6a6a;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

.write-category-modal div {
  font-size: 16px;
  color: #6a6a6a;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

.category-item.selected {
  color: #222222;
}

.listing-no-delete-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  z-index: 3;
}

.listing-update-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.listing-delete-reconfirm-modal {
  width: 100%;
  height: 330px;
  box-shadow: 0px 0px 4px 0px rgba(34, 34, 34, 0.5);
  background-color: #ffffff;
  position: fixed; /* 브라우저 화면 기준으로 위치 설정 */
  z-index: 1;
  bottom: 0px;
  left: 0px;
  border-radius: 25px 25px 0px 0px;
}

.listing-delete-modal-title {
  position: fixed;
  font-size: 24px;
  bottom: 215px;
  left: 21px;
  width: 100%;
  height: 50px;
}

.listing-delete-modal-body {
  width: 100%;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.listing-delete-modal-yes-btn {
  width: 91%;
  height: 50px;
  background-color: #222222;
  border-radius: 5px;
  position: fixed; /* 브라우저 화면 기준으로 위치 설정 */
  bottom: 100px;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.listing-delete-modal-no-btn {
  width: 90%;
  height: 50px;
  border: #222222 solid 2px;
  border-radius: 5px;
  position: fixed; /* 브라우저 화면 기준으로 위치 설정 */
  bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.listing-delete-modal-background {
  position: fixed;
  top: 0;
  left: 0; /* 위치를 왼쪽으로도 지정 */
  background-color: rgba(0, 0, 0, 0.6); /* 완전 검정에 0.5 투명도 */
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.field-error-message-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.listing-ok-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.listing-delete-ok-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

/* Link 기본 스타일 제거 */
a {
  text-decoration: none;
  color: inherit;
  font-family: "Pretendard", sans-serif;
  font-weight: 500; /* 기본값을 medium으로 설정 */
}

/* 모든 a 태그와 버튼에 커서 스타일을 추가 */
a,
button,
img {
  cursor: pointer;
}

/* OK Cursor */
.review-container2-2,
.payment-select,
.complete-checkout-main-container,
.profile-container1,
.profile-container2 div,
.profileedit-close,
.readinglist-item,
.reading-close {
  cursor: pointer;
}

.profileedit-close {
  padding: 10px 10px 10px 0px;
}

/* No Cursor */
.detail-thumbnail,
.detail-onestar,
.detail-d2-2 img,
.detail-profile-img,
.review-bigonestar,
.cart-product-thumbnail,
.review-container3-2 img,
.purchasehistory-product-thumbnail {
  cursor: default;
}

.review-container2-2 .link-reset {
  display: inline-block;
  line-height: 0; /* 줄 간격을 제거하여 높이 맞춤 */
}

.search-ProductList {
  width: 100vw;
  height: 100%;
}

.search-result-container {
  display: grid;
  grid-template-areas: "b1 b2";
  width: 100%;
  height: 180px;
  grid-template-columns: 120px 2fr;
  margin-top: 12px;
  border-bottom: solid 1.5px #f1f1f3;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  padding-bottom: 12px;
}

.readinglist-personname {
  font-weight: 400;
}

.search-b1 {
  display: flex;
  align-items: center;
  grid-area: b1;
}

.search-b2 {
  display: flex;
  align-items: center;
  grid-area: b2;
}

.profileedit-success-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.profileedit-error-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.menucategory-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.menucategory-a1 {
  display: flex;
  align-items: center;
}

.menucategory-a2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}

.menucategory-searchicon {
  padding: 8px;
}

.menucategory-homeicon {
  padding: 8px 0px 8px 8px;
}

.menucategory-ProductList {
  width: 100vw;
  height: 100%;
}

.menucategory-result-container {
  display: grid;
  grid-template-areas: "b1 b2";
  width: 100%;
  height: 180px;
  grid-template-columns: 120px 2fr;
  margin-top: 12px;
  border-bottom: solid 1.5px #f1f1f3;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  padding-bottom: 12px;
}

.menucategory-b1 {
  display: flex;
  align-items: center;
  grid-area: b1;
}

.menucategory-b2 {
  display: flex;
  align-items: center;
  grid-area: b2;
}

.homeicon {
  width: 19px;
  height: 20px;
}

.add-to-cart-modal {
  width: 80%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.main-cart-count {
  width: 14px;
  height: 14px;
  background-color: #222222;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
  right: -10px;
  top: -15px;
  z-index: 3;
}

.menu-job,
.menu-investment {
  color: #6a6a6a;
  opacity: 50%;
}

.completecheckout-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* backicon을 원래 위치에 두기 위해 필요 */
}

.complete-checkout-container {
  width: 100%;
  height: 200px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.complete-checkout-main-container {
  height: 100vh;
}

.complete-checkout-text {
  font-size: 30px;
}

.complete-checkout-confirm-text {
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-left: 4vw;
  margin-right: 4vw;
  color: #6a6a6a;
  border: none;
  font-size: 18px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.review-score-container {
  margin-top: 27px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  display: flex;
  border-bottom: 1.3px solid #f1f1f3;
}

.review-write-star-div {
  padding-right: 10px;
  padding-bottom: 10px;
}

.review-write-star {
  width: 25px;
  height: 25px;
}

.review-write-textarea {
  padding: 0;
  height: 300px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  margin-top: 16px;
  border: none;
  font-size: 18px;
  font-weight: 400;
}

.review-write-textarea::placeholder {
  font-size: 18px;
  color: #6a6a6a;
  font-weight: 400;
}

.review-write-completed-btn {
  width: 100%;
  height: 48px;
  padding: 0px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-bottom: 10px;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  background-color: #222222;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  position: fixed;
  bottom: 25px;
}

.detail-not-review {
  font-size: 18px;
}

.review-not-review {
  font-size: 19px;
  margin-top: 4px;
}

.review-success-modal,
.forgotpassword3-success-modal {
  width: 50%;
  height: 50px;
  background-color: #222222;
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 16px;
  transform: translate(-50%, -50%);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  z-index: 3;
}

.review-sort-modal {
  width: 100px;
  height: 130px;
  box-shadow: 0px 0px 4px 0px rgba(34, 34, 34, 0.15);
  border-radius: 5px;
  position: absolute;
  background-color: #ffffff;
  right: 4vw;
  top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review-sort-modal div {
  font-size: 16px;
  color: #6a6a6a;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

/* 클릭된 정렬 요소 스타일 */
.sort-option.selected {
  color: #222222; /* 클릭된 상태의 텍스트 색상 */
}

.reading-nav-container {
  width: 100%;
  height: 40px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  font-weight: 600;
}

.reading-nav-left {
  display: flex;
  align-items: center;
}

.reading-vertical-bar {
  width: 2px;
  height: 20px;
  border-radius: 100px;
  background-color: #222222;
}

.reading-fontsizeup {
  padding: 6px 0px 6px 10px;
}

.prev-page-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: none;
  padding: 10px 35px 10px 10px;
}

.next-page-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: none;
  padding: 10px 0 10px 35px; /* 위 10px, 우측 0px, 아래 10px, 좌측 10px */
}

.reading-content-container {
  width: 100%;
  height: calc(
    100vh - 50px
  ); /* 네비게이션 바를 제외한 나머지 부분의 높이를 100%로 */
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.reading-close {
  font-size: 18px;
  padding-right: 10px;
  padding: 9px 10px 9px 0px;
}

.reading-content {
  width: 100%;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  height: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25px;
  font-size: 16px;
  overflow-wrap: break-word; /* overflow가 발생하지 않도록 설정 */
  white-space: pre-wrap; /* 줄바꿈 */
  overflow: hidden; /* 페이지 넘어가는 부분 숨기기 */
  user-select: none; /* 텍스트 복사 방지 */
  font-weight: 400;
}

.page-navigation {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
}

.progress-percent {
  margin-right: 4vw;
  margin-left: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
}

.page-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: linear-gradient(
    to right,
    rgba(106, 106, 106, 1) 0%,
    rgba(106, 106, 106, 1) var(--slider-progress, 0%),
    rgba(106, 106, 106, 0.5) var(--slider-progress, 0%),
    rgba(106, 106, 106, 0.5) 100%
  );
  margin-right: 4vw;
  margin-left: 4vw;
  width: calc(100% - 8vw); /* 좌우 마진을 고려하여 너비 계산 */
  cursor: pointer;
}

.page-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.progress-percent {
  margin-bottom: 5px;
  font-size: 20px;
  color: #6a6a6a;
  font-weight: 600;
}


.helloimg {
  width: 50%;
  height: 100%;
}

.abc {
  margin-left: 15px;
}